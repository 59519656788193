export const WEBSITE_TYPES = Object.freeze({
  STANDARD: 'standard',
  TENANT: 'tenant',
});

export const AGREEMENT_TYPES = Object.freeze({
  FILE: 'file',
  URL: 'url',
});

export const USER_TYPES = Object.freeze({
  PROPCOMP: 'propcomp',
  CONTRACTOR: 'contractor',
  OWNER: 'owner',
  TENANT: 'tenant',
  EXTCO: 'extco',
  NOAUTH: 'noauth',
});

export const USER_ROLES = Object.freeze({
  ADMIN: 'admin',
  STANDARD: 'standard',
  RESTRICTED: 'restricted',
});

export const ORDER_STATUS = Object.freeze({
  PENDING_SENDING: 'pending_sending',
  PENDING_CONFIRMATION: 'pending_confirmation',
  REJECTED: 'rejected',
  PENDING_WORK: 'pending_work',
  PENDING_RATING: 'pending_rating',
  COMPLETED: 'completed',
  DELETED: 'deleted',
});

export const VISIT_BOOKING_ALLOWED_STATUSES = [ORDER_STATUS.PENDING_SENDING, ORDER_STATUS.PENDING_CONFIRMATION, ORDER_STATUS.PENDING_WORK];

export const ORDER_MESSAGE_TYPES = Object.freeze({
  MANUAL: 'manual',
  SYSTEM: 'system',
});

export const PERSON_TYPES = Object.freeze({
  NATURAL: 'natural',
  LEGAL: 'legal',
});

export const CLIENT_TYPES = Object.freeze({
  MANAGER: 'manager',
  OWNER: 'owner',
  ADMIN: 'admin',
});

export const ACCEPT_FILE_TYPES = Object.freeze({
  ALL: '*/*',
  IMAGES: 'image/*',
  OFFICE: '.xls,.xlsx,.doc,.docx',
  TEXT: '.txt,.csv',
  VIDEO: 'video/*',
  PDF: '.pdf',
});

export const TYPE_OF_SPACES = Object.freeze({
  ROOM_TYPES: 'room_types',
  COMMON_SPACE_TYPES: 'common_space_types',
});

export const userTypes = () => Object.values(USER_TYPES).map((val) => val);

export const USER_SETTINGS = Object.freeze({
  STATS_COMPARE_PERIODS: 'stats.comparePeriods',
});

export const INTEGRATION_TYPES = Object.freeze({
  ECONOMY_SYSTEM: 'economy_system',
});

export const ARTICLE_TYPES = Object.freeze({
  SERVICE: 'service',
  GOOD: 'good',
});
