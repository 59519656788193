import { gql } from '@apollo/client';

export const GET_SERVICE_CATEGORIES_FOR_OWNER = gql`
query {
  ownerServiceCategories {
    id
    name
    text
    image
    colour
    sortId
    selectable
  }
}
`;
